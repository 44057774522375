<template>
  <div>
    <h1 class="categ-title">Modele Seminee</h1>

    <read-more
      v-if="true"
      :more-str="readMore"
      :text="msg"
      link="#"
      less-str="Citeste mai putin"
      :max-chars="350"
      :class="'fireplace-read-more'"
    ></read-more>
    <div v-else class="not-mobile categs-content">
      <p v-html="msg"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModeleTitle",
  computed: {
    isMiddleDevice() {
      return this.$mq === "sm" ? true : false;
    },

    readMore() {
      return "Citeste mai mult...";
    },

    msg() {
      return '<p><span style="color:#990000"><span style="font-size:17px">Avem placerea de a va prezenta o parte din modelele de seminee executate de noi la comanda, in functie de preferintele clientilor nostri.</span></span></p><p><span style="font-size:16px">Realizam la comanda orice model de seminee, singurele restrictii fiind detaliile tehnice care trebuie respectate pentru siguranta dumneavoastra! Constructia semineelor se face doar cu materiale profesionale, fara a face rabat de la calitate si nu folosim vata cu rigips, sau orice alta metoda necorespunzatoare. Atunci cand realizam un model de semineu garantam o buna functionalitate, un plus de siguranta si o durata mare de viata! </span></p><p><span style="font-size:16px">Preturile oricaror modele de seminee se stabilesc pe baza mai multor factori, cum ar fi: amplasarea, suprafata care trebuie incalzita, daca este vorba de un model traditional sau este un model tip centrala, gabaritul semineului, cosul de fum, &nbsp;distributia aerului, optiuni suplimentare etc.</span></p><p><span style="font-size:16px"><strong>Daca doriti ca firma noastra, cu experienta de peste 15 ani, sa va construiasca un semineu pe lemne sau doriti sa va recomandam un meserias din localitatea dumneavoastra, nu ezitati sa ne <a href="/contact">contactati</a>!</strong></span></p><p>&nbsp;</p>';
    },
  },
};
</script>

<style scoped>
.fireplace-read-more {
  position: relative;
}

.categs-content {
  font-size: 14px;
}
</style>
