<template>
  <div class="default-body" id="">
    <page-header :item="header_image" />
    <slogan-livrare />

    <section id="parteneri">
      <div class="container intro">
        <div class="row justify-content-center">
          <div class="col-md-8 col-12 page-descrip text-center">
            <div class="title">
              <h3 class="h1 responsive-text">Împreună, construim calitatea.</h3>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p>
                  Suntem recunoscatori tuturor partenerilor nostri. Pentru ca suntem
                  reprezentanti oficiali in Romania pentru produse de calitate oferite de
                  catre mari producatori de seminee in toata Europa, meseriasii din toata
                  tara au ales produsele si serviciile oferite de catre firma noastra.
                  PEFOC.RO SRL este mereu in cautare de parteneri locali pentru a-si
                  extinde reteaua de distribuitori.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid bg-grey parteneri-body">
        <div class="container contact-us">
          <div class="row vertical-align text-center text-md-left">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 order2">
              <figure class="figure">
                <img src="@/assets/images/parteneri.jpg" alt="Parteneri" />
              </figure>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <h3>Suntem deschisi la orice forma de colaborare reciproc avantajoasa.</h3>
              <p class="nu-ezita">Nu ezita să ne contactezi!</p>
              <p>
                <a href="mailto:contact@pefoc.ro">
                  <span itemprop="email" class="email">contact@pefoc.ro</span>
                </a>
                <br />
                <a href="tel:0377101500">
                  <span itemprop="telephone" class="no-mobile tel">0377101500</span>
                  <span itemprop="telephone" class="mobile tel">SUNA ACUM</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/page_header/PageHeader.vue";
import SloganLivrare from "@/components/product_list/SloganLivrare.vue";

export default {
  components: {
    PageHeader,
    SloganLivrare,
  },
  computed: {
    header_image() {
      return {
        path: this.$store.getters["seo/seo_image"],
      };
    },
  },
  mounted() {
    this.$store.commit("seo/setParams", {
      seo_title: "Seminee - Parteneri",
      seo_description: "",
      seo_keywords: "",
      seo_image: require("@/assets/images/parteneri-bg.jpg"),
    });
  },
};
</script>

<style scoped>
.page-descrip {
  margin-top: 30px;
  margin-bottom: 65px;
}

#parteneri h3,
.page-descrip .h1 {
  font-size: 44px;
  color: #33343d;
  line-height: 1.18;
  font-family: Raleway Light;
}

.page-descrip p {
  font-family: Montserrat ExtraLight;
  color: #979797;
  line-height: 27px;
}

.default-body {
  background-color: #f5f4f2;
}

#parteneri img {
  width: 100%;
}

.nu-ezita {
  font-size: 14px;
  font-family: Montserrat Light;
  margin-bottom: 40px;
  margin-top: 30px;
  color: #979797;
}

.contact-us span {
  font-size: 22px;
  word-spacing: 0;
  line-height: 1.55;
  font-family: Montserrat Light;
}

@media (max-width: 767px) {
  .order2 {
    order: 2;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  #parteneri h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  h3,
  #parteneri h3,
  .page-descrip .h1 {
    font-size: 24px;
  }
}
</style>
