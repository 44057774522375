<template>
  <div>
    <div>
      <section
        class="section breadcrumb-area pb-90 page-header bg-header-img"
        style="background: url('/storage/app/media/produse.jpg') center no-repeat"
      ></section>

      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h1>
              <skeleton-box :min-width="20" :max-width="50" />
            </h1>

            <div class="content-box">
              <div class="pb-5">
                <skeleton-box />
                <skeleton-box />
                <skeleton-box />
              </div>

              <div class="row">
                <article
                  class="col-12 col-xl-3 col-lg-4 col-md-6 mb-5"
                  v-for="n in 12"
                  :key="n"
                >
                  <div>
                    <div class="block-img-wrp">
                      <skeleton-box height="190px" width="100%" />
                    </div>
                    <div>
                      <skeleton-box height="15px" width="60px" />
                    </div>
                    <div>
                      <skeleton-box />
                      <skeleton-box />
                      <skeleton-box />
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonBox from "@/components/blocks/SkeletonBox.vue";

export default {
  components: {
    SkeletonBox,
  },
};
</script>
