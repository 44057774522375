<template>
  <div>
    <div v-if="filteredFireplace.length" id="modele-holder" class="vld-parent">
      <div class="row grid">
        <div class="grid-sizer"></div>
        <card-model
          v-for="(fireplace, index) in fireplaces"
          :key="fireplace.id"
          :fireplace="fireplace"
          :class="'grid-item grid-current'"
          @click="() => show(index)"
        />
        <loading
          :active="isLoading || (hasFetchedAllData && showSpinner)"
          :lock-scroll="true"
          :opacity="0.2"
        >
          <spinner :spinner="isLoading">
            Se incarca modelele
            <template v-slot:noitems>Nu mai sunt modele de incarcat</template>
          </spinner>
        </loading>
      </div>
      <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
        :class="'fireplace-modal'"
      ></vue-easy-lightbox>
    </div>
    <div v-else class="pf-no-products">
      <p>Nu exista nici un produs care sa contina criteriile selectate.</p>
      <p>Va rog schimbati criterile de filtrare!</p>
    </div>
  </div>
</template>

<script>
import CardModel from "@/components/modele/CardModel.vue";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import VueEasyLightbox from "vue-easy-lightbox";
import Spinner from "@/components/blocks/Spinner.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "ModeleContent",
  data() {
    return {
      fireplaces: [],
      imgs: [],
      visible: false,
      index: 0,

      isLoading: false,
      currentPage: 0,
      totalPages: "",
      showSpinner: true,
    };
  },
  components: {
    CardModel,
    VueEasyLightbox,
    Spinner,
    Loading,
  },
  watch: {
    filteredFireplace: async function () {
      await this.resetFireplaces();
      await this.fetchPosts(true);
      this.totalPages = Math.ceil(this.filteredFireplace.length / 60);
      this.imgs = [];
      for (var i = 0; i < this.filteredFireplace.length; i++) {
        this.imgs.push(this.filteredFireplace[i].preview_image);
      }
    },
    hasFetchedAllData: function () {
      setTimeout(() => (this.showSpinner = false), 1000);
    },
  },

  computed: {
    // fireplaces() {
    //   return this.$store.getters['fireplace/fireplaces'];
    // },

    hasFetchedAllData() {
      return this.currentPage === this.totalPages && !this.isLoading;
    },

    filteredFireplace() {
      return this.$store.getters["fireplace/filteredFireplace"];
    },
  },

  methods: {
    show(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },

    async masonryLayout() {
      var grid = await document.querySelector(".grid");
      var msnry = new Masonry(grid, {
        // options...
        columnWidth: ".grid-sizer",
        itemSelector: ".grid-item",
        // percentPosition: true
      });

      // var layout_msnry = this.msnry;
      imagesLoaded(grid).on("done", function () {
        // layout Masonry after each image loads
        msnry.layout();
      });
    },

    async handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        if (this.hasFetchedAllData || this.isLoading) {
          return;
        }

        this.isLoading = true;
        await this.fetchPosts();
        setTimeout(() => (this.isLoading = false), 500);
      }
    },

    async fetchPosts(msr = false) {
      try {
        this.currentPage++;

        const startInt = (this.currentPage - 1) * 60;
        let endInt = this.currentPage * 60 - 1;

        if (endInt > this.filteredFireplace.length) {
          endInt = this.filteredFireplace.length;
        }
        const response = this.filteredFireplace.slice(startInt, endInt);

        Array.prototype.push.apply(this.fireplaces, response);

        for (var i = startInt; i < endInt; i++) {
          this.imgs.push(this.filteredFireplace[i].preview_image);
        }
        if (this.currentPage > 1 || msr) {
          this.masonryLayout();
        }
      } catch (err) {
        console.log(err); // state-of-the-art-error-handling
      }
    },
    resetFireplaces() {
      this.fireplaces = [];
      this.currentPage = 0;
    },
  },
  created() {
    this.fetchPosts();
    this.totalPages = Math.ceil(this.filteredFireplace.length / 60);
  },

  mounted() {
    this.$nextTick(function () {
      this.masonryLayout();
    });

    window.addEventListener("scroll", this.handleScroll);
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.grid-sizer,
.grid-item {
  width: 25%;
  padding: 0 15px;
}

.grid-item {
  text-align: left;
}

@media (min-width: 768px) {
  .fireplace-modal.vel-img-modal .vel-btns-wrapper .btn__next,
  .fireplace-modal.vel-img-modal .vel-btns-wrapper .btn__prev,
  .fireplace-modal.vel-img-modal .vel-btns-wrapper .btn__close {
    background: #000;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .fireplace-modal.vel-img-modal .vel-btns-wrapper .btn__close {
    top: 110px;
  }
}

@media (max-width: 991px) {
  .grid-sizer,
  .grid-item {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .grid-sizer,
  .grid-item {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .grid-sizer,
  .grid-item {
    width: 100%;
  }
}
</style>
