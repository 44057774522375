<template>
  <div class="filter-row">
    <button
      class="button"
      :class="{ 'is-checked': active }"
      :data-filter="specValue.key"
      @click="setSpec($event)"
    >
      {{ specValue.value }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["specValue", "specGroup", "active", "index"],
  emits: ["set-active"],
  data() {
    return {
      specifications: {
        style: "",
        shape: "",
      },
    };
  },
  methods: {
    setSpec(event) {
      const selectedSpec = {
        spec: this.specGroup,
        value: event.target.getAttribute("data-filter"),
      };
      this.$store.dispatch("fireplace/specification", selectedSpec);

      this.$emit("set-active", this.index);
      // console.log(this.index);

      this.$store.dispatch("fireplace/filterBySpec").then(() => {
        let anchor = document.querySelector("#models");
        anchor.scrollIntoView({
          behavior: "smooth",
        });
      });
    },
  },
};
</script>
