<template>
  <div class="section filtersNavbar" id="fireplace-filter">
    <div class="container">
      <div class="filters filter-content filter-header d-sm-block">
        <div class="filter-items filter-default filters-mobile" id="filter_blog">
          <div>
            <div class="row">
              <model-filter-item
                :specification="style"
                class="col-xl-5 col-md-7 filter-by-style"
                ref="filter_style"
              />

              <model-filter-item
                :specification="shape"
                :class="'col-xl-3 col-md-5  filter-by-shape'"
                ref="filter_shape"
              />

              <div class="item col-xl-4 col-lg-12 col-md-12 col-12 filter-by-number">
                <div class="border-grey"></div>
                <div class="filter-name">
                  <span class="filter-label d-none d-sm-block">Nr model:</span>
                  <span class="filter-label d-block d-sm-none"
                    >Filtreaza dupa numar model:</span
                  >
                </div>
                <div class="button-group js-radio-button-group" data-filter-group="state">
                  <div class="filter-row">
                    <input
                      v-model="goTo"
                      placeholder="ex. 44 "
                      type="text"
                      name="go_to"
                      id="go_to"
                      class="quicksearch"
                      v-on:keyup.enter="searchFireplace"
                    />
                    <input
                      type="submit"
                      name="model-finder"
                      class="btn btn-default"
                      id="model-finder"
                      value="Salveaza si vezi rezultatele"
                      @click="searchFireplace"
                    />
                    <button class="btn line-grey d-none d-sm-block"></button>
                    <button
                      v-if="isFiltered"
                      class="button button-reset btn btn-default d-none d-md-block"
                      @click="resetFilter"
                    ></button>
                  </div>
                </div>
              </div>

              <div class="col-12 action-buttons">
                <div class="products-count d-block d-md-none">
                  {{ countFireplace }} rezultate
                </div>

                <pf-button
                  :class="'btn btn-red btn-large filter-mobile d-block d-md-none'"
                  @click="closeFilter"
                >
                  Salveaza si vezi rezultate
                </pf-button>
              </div>
            </div>

            <div class="products-count d-block d-sm-none"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModelFilterItem from "@/components/filter/models/ModelFilterItem.vue";
import PfButton from "@/components/buttons/PfButton.vue";

export default {
  data() {
    return {
      goTo: "",
      style: {
        key: "style",
        title: "stil",
        data: [
          {
            id: 1,
            key: "modern",
            value: "Modern",
          },
          {
            id: 2,
            key: "rustic",
            value: "Rustic",
          },
          {
            id: 3,
            key: "clasic",
            value: "Clasic",
          },
        ],
      },
      shape: {
        key: "shape",
        title: "forma",
        data: [
          {
            id: 1,
            key: "drept",
            value: "Drept",
          },
          {
            id: 2,
            key: "colt",
            value: "Pe colt",
          },
        ],
      },
    };
  },
  components: {
    ModelFilterItem,
    PfButton,
  },
  name: "ModeleFilter",
  emits: ["closeMobileFilter"],
  computed: {
    countFireplace() {
      return this.$store.getters["fireplace/countFilteredFireplace"];
    },
    isFiltered() {
      return this.$store.getters["fireplace/isFiltered"];
    },
  },
  methods: {
    searchFireplace() {
      this.resetFilter();
      this.$store.dispatch("fireplace/changeFilteredState", true);
      this.$store.dispatch("fireplace/getFireplacesBySearch", +this.goTo);
    },

    selectSpec(event) {
      console.log(event.target.getAttribute("data-filter"));
    },

    resetFilter() {
      this.$store.dispatch("fireplace/changeFilteredState", false);
      this.$store.dispatch("fireplace/resetFilter");
      this.$refs.filter_style.activeIndex = -1;
      this.$refs.filter_shape.activeIndex = -1;
      let anchor = document.querySelector("#models");
      anchor.scrollIntoView({
        behavior: "smooth",
      });
    },

    closeFilter() {
      this.$emit("closeMobileFilter");
    },
  },
};
</script>

<style scoped>
.sticky.filtersNavbar {
  position: fixed;
  top: 0;
  width: 100%;
  /* height: 70px; */
  background-color: rgba(0, 0, 0, 1);
  z-index: 16;
}

.filter-by-number .filter-name {
  background-image: url(~@/assets/images/line.png);
  background-position: left;
  background-repeat: no-repeat;
  padding-left: 15px;
}

@media (max-width: 768px) {
  #fireplace-filter {
    height: 100%;
    padding-top: 110px;
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .filter-items.filter-default .filter-name {
    padding: 0 30px;
    height: 50px;
    line-height: 50px;
  }

  .border-grey {
    background: grey;
    height: 1px;
    margin: 15px;
  }

  input#go_to {
    width: auto;
  }

  .products-count {
    /* position: absolute; */
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 10px 20px;
    color: #9b1200;
    font-weight: 600;
    font-family: Montserrat SemiBold;
  }

  .action-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: fixed;
    bottom: 50px;
    left: 0;
  }
}

@media (max-width: 576) {
  #fireplace-filter {
    padding-top: 60px;
  }
}
</style>
