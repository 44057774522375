<template>
  <div class="box photo">
    <a rel="modele_image" data-title="">
      <p class="number hidden"></p>

      <div class="model-holder">
        <div class="model-img-holder" ref="img" :class="'img_' + fireplace.id">
          <div class="overlay"></div>
          <img class="zoomphoto" src="@/assets/images/modele/zoom_icon.png" />
          <div class="buttons-holder">
            <div v-if="fireplace.style" class="filter-type">{{ fireplace.style }}</div>
            <div v-if="fireplace.shape" class="filter-type">{{ fireplace.shape }}</div>
          </div>
          <!-- <img
                class="lazy"
                data-original=""
                width=""
                height=""
                src="@/assets/images/modele/overlay.png"
            /> -->
          <img
            loading="lazy"
            class="lazy"
            data-original=""
            width=""
            height=""
            :src="fireplace.thumb_image"
          />
        </div>
        <div class="model-details-holder">
          <div class="model-title">
            <span>{{ fireplace.title }}</span>
          </div>
          <div class="model-description">{{ fireplace.description }}</div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: ["fireplace"],
  mounted() {
    var el = document.querySelector(".img_" + this.fireplace.id);
    let imageHeight =
      (this.$refs.img.clientWidth * this.fireplace.thumb_sizes.height) /
      this.fireplace.thumb_sizes.width;
    el.style.height = imageHeight + "px";
  },
};
</script>
