<template>
  <div v-if="isDataLoad" class="site-content modele-content">
    <page-header :item="header_image" />
    <template v-if="filteredFireplace.length">
      <transition name="fade">
        <modele-filter
          :class="{
            sticky: scrollNavbar,
            mobile: windowWidth < 768 && !navbarFilterMobile,
          }"
          @closeMobileFilter="closeFilter"
        />
      </transition>
      <!-- <navbar-filter-mobile v-show="windowWidth < 768 && navbarFilterMobile" /> -->
      <mobile-filter @click="showMobileFilter" />
      <div id="models">
        <div class="container">
          <div class="models-list">
            <modele-title />
            <modele-content />
          </div>
        </div>
      </div>
    </template>
  </div>
  <SkeletonFireplace v-if="!isDataLoad" />
</template>

<script>
import ModeleFilter from "@/components/filter/ModeleFilter.vue";
import MobileFilter from "@/components/filter/MobileFilter.vue";
import ModeleTitle from "@/components/modele/ModeleTitle.vue";
import ModeleContent from "@/components/modele/ModeleContent.vue";
import PageHeader from "@/components/page_header/PageHeader.vue";
import { generalMixin } from "@/mixins/generalMixin";
import SkeletonFireplace from "@/components/blocks/skeleton/fireplace/SkeletonFireplace.vue";

export default {
  name: "Modele",
  data() {
    return {
      windowWidth: window.innerWidth,
      navbarFilterMobile: false,
      scrollNavbar: false,
    };
  },
  components: {
    "modele-filter": ModeleFilter,
    "modele-title": ModeleTitle,
    "modele-content": ModeleContent,
    "page-header": PageHeader,
    "mobile-filter": MobileFilter,
    SkeletonFireplace,
  },
  computed: {
    filteredFireplace() {
      // console.log(this.$store.getters["fireplace/filteredFireplace"]);
      return this.$store.getters["fireplace/filteredFireplace"];
    },
    header_image() {
      return {
        path: this.$store.getters["seo/seo_image"],
      };
    },
  },

  methods: {
    handleScroll() {
      // console.log(window.scrollY);
      if (window.scrollY > 260) {
        this.scrollNavbar = true;
      } else {
        this.scrollNavbar = false;
      }
    },

    showMobileFilter() {
      this.navbarFilterMobile = true;
    },

    closeFilter() {
      this.navbarFilterMobile = false;
      this.$store.dispatch("filter/actionImgFilter", true);
      this.scrollToTop();
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },

  mixins: [generalMixin],

  beforeCreate() {},

  mounted() {
    // console.log(this.isDataLoad);
    this.$store.dispatch("fireplace/getFireplaces").then(() => {
      // this.$nextTick(() => {
      const self = this;
      setTimeout(() => {
        self.$store.dispatch("shop/actCategoryIsLoad", true);
      }, 2500);
      // })
    });
    window.addEventListener("scroll", this.handleScroll);
    this.$store.commit("seo/setParams", {
      seo_title: "Modele seminee construite in Cluj, Bucuresti, Brasov, Iasi",
      seo_description:
        "Construim la comanda seminee moderne sau rustice, la preturi avantajoase. Vedeti modele de seminee, realizate in Cluj, Bucuresti, Iasi, Brasov si alte orase.",
      seo_keywords:
        "seminee bucuresti, modele seminee, semineu cluj, brasov, constructie seminee iasi",
      seo_image: require("@/assets/images/modele3.webp"),
    });
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    this.$store.dispatch("shop/actCategoryIsLoad", false);
  },
};
</script>

<style>
@import "../../assets/css/modele.css";
@import "../../assets/css/style.css";
@import "../../assets/css/mobile-filter.css";
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
