<template>
    <div class="item col-lg-6 col-sm-6 col-12">
        <div class="filter-name">
            <span class="filter-label d-none d-sm-block"
            >{{ prefixTitle + specification.title}}:</span
            >
            <span class="filter-label d-block d-sm-none"
            >Filtreaza dupa {{ specification.title }}:</span
            >
        </div>
        <div
            class="button-group js-radio-button-group"
            :data-filter-group="specification.title"
        >
            <spec-value 
                v-for="(spec, index) in specification.data" 
                :key="spec.id" 
                :spec-value="spec" 
                :spec-group="specification.key"
                :active="index === activeIndex"
                :index="index" 
                @set-active="activeIndex = $event"
            />
        </div>
    </div>
</template>

<script>
import SpecValue from '@/components/filter/models/SpecValue.vue';

export default {

    data() {
        return {
            activeIndex: -1,
        }
    },

    props: ['specification'],
    components: {
        SpecValue
    },

    computed: {
        prefixTitle() {
            
            return this.specification.title === 'stil' ? 'Filtreaza dupa ' : 'Dupa ';
        }
    }

}
</script>

<style scoped>

@media (max-width: 768px) {

  .filter-items.filter-default .filter-name  {
    padding: 0 30px;
    height: 50px;
    line-height: 50px;
  }

}

</style>